<template>
	<div id="app">
		<Nav></Nav>
		<router-view></router-view>
	</div>
</template>

<script>

import Nav from './Nav.vue'
export default {
	components: {Nav}
}
</script>

<style lang="scss">

*, *::before, *::after {
	box-sizing: border-box;
}

#app {
	margin: 0 auto;
	line-height: 1.4;
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
	text-align: center;
}




.selectable{
    cursor:pointer !important ;
     -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

</style>