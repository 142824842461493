
Storage = window.localStorage;
const key = "patterns";
const key2 = "timeline";

let getFromAPI = async function(){
    let res = await fetch('https://patterns.johndews.com/db')
    res = await res.json();
    for(let item of res){
        localStorage.setItem(...item)
    }
    return true;
}

let saveKeyToAPI = async function(key, value){
    return await fetch('https://patterns.johndews.com/db', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({keyname:key, value})
    });
}

let saveAllToAPI = function(){
    for(let item of Object.entries(localStorage)){
        saveKeyToAPI(...item)
    }
}

let Store = {
    saveWorkspace(timelineId, patternsId){
        let keyName = key+patternsId;
        let value = localStorage.getItem(keyName);
        let keyName2 = key2+timelineId;
        let value2 = localStorage.getItem(keyName2);
        saveKeyToAPI(keyName, value);
        saveKeyToAPI(keyName2, value2)
    },
    getPatterns(workspaceID){
        if(!workspaceID) workspaceID = "";
        let p = localStorage.getItem(key+workspaceID);
        if(!p) return [];
        return JSON.parse(p);
    },
    savePatterns(patternObject, workspaceID){
        if(!workspaceID) workspaceID = "";
        if(!patternObject) patternObject = [];
        let p = localStorage.setItem(key+workspaceID, JSON.stringify(patternObject));
    },
    saveTimeline(patternObject, workspaceID){
        if(!workspaceID) workspaceID = "";
        if(!patternObject) patternObject = [];
        let p = localStorage.setItem(key2+workspaceID, JSON.stringify(patternObject));
    },
    getTimeline(workspaceID){
        if(!workspaceID) workspaceID = "";
        let p = localStorage.getItem(key2+workspaceID);
        if(!p) return [];
        return JSON.parse(p);
    },
    getTimelineList(){
        let x = Object.entries(localStorage)
        return x.filter(x=>x[0].includes('timeline'))
    },
    getPatternsList(){
        let x = Object.entries(localStorage)
        return x.filter(x=>x[0].includes('patterns'))
    }
};


Store.getFromAPI = getFromAPI;
Store.saveAllToAPI = saveAllToAPI

export default Store;