<template>
  <nav
    class="navbar navbar-expand-lg navbar-light"
    style="background-color: #e3f2fd"
  >
    <div class="container">
      <a class="navbar-brand" href="#">Snowflake Animator</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              to="/animate"
              class="nav-link"
              aria-current="page"
              href="#"
              >Default</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/" class="nav-link">Workspace Select</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/board" class="nav-link">Board View</router-link>
          </li>
        </ul>
        <span class="navbar-brand">
          <span v-if="$route.params.timelineId"
            ><small>Timeline:</small>
            <b>{{ $route.params.timelineId }}</b></span
          >
          <span class="ps-3" v-if="$route.params.patternsId"
            ><small>Patterns:</small>
            <b>{{ $route.params.patternsId }}</b></span
          >
        </span>
      </div>
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style>
</style>