<template>
  
  <div>
    <ul class="list-group">
        <li class="list-group-item">An item</li>
        <li class="list-group-item">A second item</li>
        <li class="list-group-item">A third item</li>
        <li class="list-group-item">A fourth item</li>
        <li class="list-group-item">And a fifth one</li>
    </ul>
  </div>


</template>

<script>

import utils from "./utils";

export default {

}
</script>

<style>

.keyframe {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 10px;
  float: left;
  border-radius: 15px;
  line-height: 30px;
  background-color: red;
}

</style>