<template>
  <div>


      <div class="container-fluid">
                <div class="row"> 
          <div class="col-6">
                <div class="board" v-bind:style="{ width: (width) +'px', height: (height) + 'px' }">
                    <div v-for="light in lights" class="light" @click="toggle(light)" v-bind:style="{ left: (light.x*width) +'px', top: (light.y*height) + 'px', background: light.on ? light.color : 'black' }"></div>
                    
                    <div class="digits"  v-bind:style="{ left: (0.55*width) +'px', top: (0.6*height) + 'px' }">
                        {{digits[0]}}
                    </div>
                        <div class="digits"  v-bind:style="{ left: (0.8*width) +'px', top: (0.6*height) + 'px' }">
                        {{digits[1]}}
                    </div>

                    <div style="clear: both;"></div>
                </div>
          </div>
          <div class="col-6">

              

         <ul class="list-group">
            <li class="list-group-item" v-for="pattern in patterns">
                
                <div class="keyframe" v-bind:style="{ background: getFrameColor(pattern.id) }">
                    {{pattern.id}}
                </div>
                
                {{pattern}}</li>
    
        </ul>









          </div>
      </div>
      </div>



    <Timeline :patterns="patterns"></Timeline>

  </div>
</template>

<script>

import control from "./mqtt"; 
import Timeline from './Timeline.vue';
import Patterns from './Patterns.vue';
import utils from './utils';


let segments = [];
        segments[0] = [1, 1, 1, 1, 1, 1, 0];
        segments[1] = [0, 1, 1, 0, 0, 0, 0];
        segments[2] = [1, 1, 0, 1, 1, 0, 1];
        segments[3] = [1, 1, 1, 1, 0, 0, 1];
        segments[4] = [0, 1, 1, 0, 0, 1, 1];
        segments[5] = [1, 0, 1, 1, 0, 1, 1];
        segments[6] = [1, 0, 1, 1, 1, 1, 1];
        segments[7] = [1, 1, 1, 0, 0, 0, 0];
        segments[8] = [1, 1, 1, 1, 1, 1, 1];
        segments[9] = [1, 1, 1, 1, 0, 1, 1];

let getColForNum = function(n){
    return segments[n];
}


export default {
    components: {Timeline, Patterns},
    mounted(){

        control.setPixel("*",0,0)
        let i  = 0;
        let self = this;
        setInterval(function(){
            i++;
            let s = String(i);
            if(s.length == 1) s = "0"+s;
            self.digits = s;
            self.setDigit(4, s[0]);
            self.setDigit(5, s[1]);

            if(i > 100) i = 0;

        }, 400)
  

    },
    methods:{
        getFrameColor(n){
            return utils.getFrameColor(n);
        },
        toggle(light){
            let id = light.id;
            control.setPixel(id[0],id[1], !light.on);
            light.on = !light.on;
        },
        setValue(x,y,value){
            for(let light of this.lights){
                //console.log(light.id,x,y)
             if(light.id[0] == x && light.id[1] == y){
                     if(value != light.on){
                        this.toggle(light);
                    }
                }
            }
        },
        setDigit(row, digit){
            let values = getColForNum(digit);
            let i=0;
            for(let value of values){
               this.setValue(row,i++, value);
            }
        },
        getColor(row){
            switch (row) {
                case 0:               
                    return "white";
                case 1:             
                    return "green";
                case 2:              
                    return "blue";
                default:
                    return "red";
            }
        },
        generateGrid(){
            let x_offset = 0.111, x_start = 0.10, y_offset = 0.143, y_start = 0.041;
            let lights = [];
            for(let i = 0; i < 6; i++){
                if(i>3) {
                    y_start = 0.75;
                    y_offset = 0.07;
                    x_offset = 0.07;
                    x_start = 0.45;
                }
                for(let j = 0; j < 8; j++){
                    lights.push({
                        x: x_start + x_offset*j,
                        y: y_start + y_offset*i,
                        color: this.getColor(i),
                        id: [i,j],
                        on: false
                    })
                }
            }
           // console.log(lights)
            return lights;
        }
    },
    data(){
        let lights = this.generateGrid();
        return {
            width: 537,
            height: 428,
            lights,
            digits: "00",
            patterns: [{id: 0, grid: new Array(lights.length).fill(0) }]
        }
    }

}
</script>

<style scoped>

.board{
    background: url('./board.png');
    background-repeat: no-repeat;
    position: relative;
    background-size: contain;
    border: black 1px solid;
    margin: auto;
    margin-bottom: 100px; 
}
.light{
    min-width: 30px;
    min-height: 30px;
     border: white 1px solid;
     background: white;
     display: inline;
     position: absolute;
     border-radius: 10px;
     opacity: 0.9;
}
.digits{
    background: black;
    color: red;
    opacity: 0.8;
    border: white 1px solid;
    display: inline;
     position: absolute;
     font-size: 100px;
}


.keyframe {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 10px;
  float: left;
  border-radius: 15px;
  line-height: 30px;
  background-color: red;
}

</style>