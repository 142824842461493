<template>
  <div>
    <div class="time-buttons selectable">
      <span @click="play()" class="material-icons">play_arrow</span>
      <span @click="stop()" class="material-icons">stop</span>

      <span @click="addSegment()" class="material-icons">playlist_add</span>
      <span @click="segments.pop()" class="material-icons">playlist_remove</span>
      <span @click="save()" class="material-icons">file_download</span>
    </div>

    <div class="title center">Timeline</div>
    <div class="timeline">
      <div
        class="segments"
        v-bind:style="{ width: (segments.length + 1) * segWidth + 'px' }"
      >
        <div class="segment">
          <div class="time">Time [sec]</div>
          <span class="vertical p-2 center pt-2">Patterns</span>
        </div>
        <div
          class="segment"
          v-for="(segment, index) in segments"
          v-on:click.alt="requestFrame(segment)"
        >
          <div class="time" :class="{ active: index == step }">
            {{ segment.time }}
          </div>
          <div
            v-for="(frame, index2) in segment.frames"
            v-on:click.ctrl="segment.frames.splice(index2, 1)"
            v-on:click.exact="increment(index, index2, 1)"
            @contextmenu.prevent="increment(index, index2, -1)"
            class="keyframe"
            v-bind:style="{ background: getFrameColor(frame.value) }"
          >
            {{ frame.value }}
          </div>
        </div>
      </div>
      <div style="clear: all"></div>

      <vue-simple-context-menu
        :elementId="'myUniqueId'"
        :options="options"
        :ref="'vueSimpleContextMenu'"
        @option-clicked="optionClicked"
      />
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import utils from "./utils";
import Store from "./store";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export default {
  components: { draggable },
  props: ["patterns"],
  data() {
    let segments = [];
    let timestep = 0.5;
    for (let i = 0; i < 12; i++) {
      let frames = new Array(getRandomInt(4)).fill(0);
      frames = frames.map((x) => {
        return { value: getRandomInt(5) };
      });
      frames = [{ value: i }];
      segments[i] = { time: timestep * i, frames };
    }

    let timelineId = this.$route.params.timelineId || "";
    let storedSegments = Store.getTimeline(timelineId);
    if (storedSegments.length > 0) segments = storedSegments;
    return {
      segments,
      segWidth: 40,
      options: [{ name: "Delete" }],
      timestep,
      step: 0,
      timelineId,
    };
  },
  watch: {
    segments() {
      this.$emit("updated", this.segments);
      console.log("Timeline updated!");
      Store.saveTimeline(this.segments, this.timelineId);
    },
  },
  beforeDestroy() {
    this.stop();
  },
  methods: {
    save() {
      let lightFrames = [];
      let i = 0;
      let str = "{";
      for (let segment of this.segments) {
        let lights = this.computeLight(segment.frames);
        str += "{" + lights.join(",") + "," + segment.time * 1000 + "},";
        lightFrames[i++] = lights;
      }
      str += "};";

      console.log(lightFrames);
      console.log(str);
    },

    play() {
      let self = this;
      if (this.timer) this.stop();
      this.timer = setInterval(function () {
        let segment = self.segments[self.step];
        self.step++;
        if (!segment || self.step == self.segments.length) self.step = 0;

        let lights = self.computeLight(segment.frames);
        console.log(self.step, lights);
        self.$emit("setLights", lights);
      }, this.timestep * 1000);
    },

    stop() {
      if (this.timer) clearInterval(this.timer);
      this.step = 0;
      this.timer = null;
    },

    computeLight(frames) {
      let output = new Array(this.patterns[0].lights.length).fill(0);
      for (let frame of frames) {
        let patternId = frame.value;
        for (let i in output) output[i] += this.patterns[patternId].lights[i];
      }
      return output;
    },

    addSegment() {
      this.segments.push({
        time: this.segments[this.segments.length - 1].time + this.timestep,
        frames: [],
      });
      Store.saveTimeline(this.segments, this.timelineId);
    },
    getFrameColor(n) {
      return utils.getFrameColor(n);
    },
    increment(index, index2, v) {
      this.segments[index].frames[index2].value += v;
      if (this.segments[index].frames[index2].value < 0)
        this.segments[index].frames[index2].value = 0;
      this.$forceUpdate();
      Store.saveTimeline(this.segments, this.timelineId);
    },
    handleClick(event, item) {
      this.$refs.vueSimpleContextMenu.showMenu(event, item);
    },

    optionClicked(event) {
      window.alert(JSON.stringify(event));
    },
    requestFrame(segment) {
      //let numb = Number(prompt("Enter a Pattern Number", "1"));
      let numb = 1;
      if (numb) segment.frames.push({ value: numb });
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
}
.title {
  background-color: black;
  color: white;
}

.time-buttons span {
  margin: 5px;
  border: 1px solid;
  padding: 3px;
  display: inline-block;
}
.timeline {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  width: 100%;
  min-height: 150px;
  background: rgb(187, 187, 187);
  overflow-x: auto;
}

.keyframe {
  width: 30px;
  height: 25px;
  margin-left: 5px;
  margin-top: 5px;
  float: left;
  border-radius: 15px;
  line-height: 30px;
  background-color: red;
}

.material-icons{
    display: inline-flex;
    vertical-align: top;
}

.active {
  background: green !important;
}

.segment {
  width: 40px;
  min-height: 150px;
  float: left;
  text-align: center;
  border: 1px rgb(44, 44, 44) solid;
  .time {
    width: 100%;
    background: white;
    color: black;
    text-align: center;
    font-size: 10px;
  }
  .vertical {
    writing-mode: vertical-rl;
  }
}
</style>