import 'regenerator-runtime/runtime'
import Vue from 'vue/dist/vue.js';
import VueRouter from 'vue-router'
import App from './App'
import Home from './home.vue'

global.MQTT = require('./mqtt');

import Display from './Display.vue'
import DisplaySnowFlake from './DisplaySnowFlake.vue'
import DisplaySnowFlakeAnimate from './DisplaySnowFlakeAnimate.vue'

import "bootstrap/dist/css/bootstrap.min.css";
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
import VueSimpleContextMenu from "vue-simple-context-menu";
import 'material-icons/iconfont/material-icons.css';


Vue.component("vue-simple-context-menu", VueSimpleContextMenu);
Vue.use(VueRouter);
Vue.config.productionTip = false

const router = new VueRouter({
  routes: [
    { path: '/', component: Home },
    { path: '/board', component: DisplaySnowFlake },
    { path: '/animate', component: DisplaySnowFlakeAnimate },
    { path: '/animate/:timelineId', component: DisplaySnowFlakeAnimate },
    { path: '/animate/:timelineId/:patternsId', component: DisplaySnowFlakeAnimate },
    { path: '/old', component: Display }
  ]
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  template: '<App/>',
  components: { App },
  router
});