<template>
  <div class="container">
    <div class="card m-3">
      <div class="card-body">
        <h3>Saved Timelines</h3>
        <p>
          Timelines are a sequence of frames which can contain mutliple patterns
        </p>
        <div v-for="timeline in timelines" class="selectable p-1" @click="selectTimeline(timeline[0], timeline)">
          {{ timeline[0] }} <span class="badge bg-secondary" v-if="timeline[0]=='timeline'">Default</span>
        </div>
      </div>
    </div>

    <div class="card m-3">
      <div class="card-body">
        <h3>Saved Patterns</h3>
        <p>Patterns are differing light arrangements</p>
        <div v-for="pattern in patterns" class="p-1 selectable">
          <input type="radio" :value="pattern[0]" :id="pattern[0]" v-model="patternPicked" name="patternSelected"> <label :for="pattern[0]">{{ pattern[0] }} <span class="badge bg-secondary" v-if="pattern[0]=='patterns'">Default</span></label>
        </div>
      </div>
    </div>

     <div class="card m-3">
      <div class="card-body">
        <h3>New Workspace</h3>
        <input type="text" v-model="newTimeline" placeholder="Timeline Name">
        <input type="text" v-model="newPattern" placeholder="Pattern Name (Blank For Default)">
        <button v-if="newTimeline" @click="createNew()">Create</button>
      </div>
    </div>


    <button @click="getFromAPI()">Load From Cloud</button>
    <button @click="saveAllToAPI()">Save All to Cloud</button>



  </div>
</template>

<script>
import Store from "./store";

export default {
  data(){
      return {
          patternPicked: "patterns",
          newTimeline: null,
          newPattern: null,
          timelines: [],
          patterns: []
      }
  },
  mounted(){
      this.getValues();

  },
  methods:{
    async getFromAPI(){
      await Store.getFromAPI();
      this.getValues();
      this.$forceUpdate();
    },
    async saveAllToAPI(){
      await Store.saveAllToAPI();
    },
    getValues(){
      this.timelines = Store.getTimelineList();
      this.patterns = Store.getPatternsList();
    },
    createNew(){
        if(this.newPattern){
            this.$router.push('/animate/'+this.newTimeline+"/"+this.newPattern)
        }else{
             this.$router.push('/animate/'+this.newTimeline)
        }
    },
    selectTimeline(id, timeline){
        if(id == 'timeline' && this.patternPicked == "patterns"){
            this.$router.push('/animate');
            return;
        }else if(this.patternPicked == "patterns"){
             console.log('here!')
            this.$router.push('/animate/'+id.replace('timeline',""))
           
        }else{
            this.$router.push('/animate/'+id.replace('timeline',"")+"/"+this.patternPicked.replace('patterns',""))
        }
    }
  }
 
};
</script>

