<template>
  <div>
    <div class="container pb-4 pt-2" style="max-width: 2000px">
      <div class="row">
        <div class="col-7 center">
          <div
            class="board"
            v-bind:style="{ width: width + 'px', height: height + 'px' }"
          >
            <div
              v-for="light in lights"
              class="light selectable"
              v-bind:class="{ lit: light.on }"
              @click="toggle(light)"
              v-bind:style="{
                left: light.x * width - 15 + 'px',
                top: light.y * height - 10 + 'px',
                background: light.color,
              }"
            ></div>
            <div style="clear: both"></div>
          </div>

    


        </div>
        <div class="col-5">
          <h3 class="center">Patterns ({{patterns.length}})</h3>
          <div class="patterns">
            <div
              class="pattern row"
              v-for="pattern in patterns"
              @click="selectPattern(pattern)"
            >
              <div
                v-bind:style="{ background: getFrameColor(pattern.id) }"
                class="col-2 center keyBox"
              >
                {{ pattern.id }}
              </div>
              <div class="col-7">
                <span
                  ><input type="text" v-model="pattern.name" /> ({{
                    pattern.count
                  }})</span
                >
              </div>
              <div class="col-3 selectable">
                <span @click="removePattern(pattern.id)" class="material-icons"
                  >delete</span
                >
                <span @click="copyPattern(pattern.id)" class="material-icons"
                  >file_copy</span
                >
              </div>
            </div>
          </div>

      <div class="controls">

          <br>

          <button @click="savePattern()">Save New Pattern</button>
          <button @click="all(false)">ALL OFF</button>
          <button @click="all(true)">ALL ON</button>
          <button @click="save()">Save To Cloud</button>

          <input
            type="checkbox"
            id="updates"
            name="updates"
            v-model="liveUpdate"
          />
          <label for="updates">Enable Live Updates</label><br />
          </div>

        </div>
      </div>
    </div>
    <Timeline :patterns="patterns" @setLights="setLights"></Timeline>
  </div>
</template>


<script>
import control from "./mqtt";
import Timeline from "./Timeline.vue";
import Patterns from "./Patterns.vue";
import utils from "./utils";
import Store from "./store";
let placements = require("./placement.json");

export default {
  components: { Timeline, Patterns },
  mounted() {
    console.log("placements", placements);
    control.setPixel("*", 0, 0);
  },
  watch: {
    patterns() {
      Store.savePatterns(this.patterns, this.patternsId);
    },
  },
  methods: {
    save(){
      Store.saveWorkspace(this.timelineId, this.patternsId)
    },
    selectPattern(pattern) {
      this.setLights(pattern.lights);
    },
    setLights(lights) {
      console.log("setlights!", lights);
      for (let l in lights) {
        let i = Math.floor(l / 8);
        let j = l % 8;
        this.setValue(i, j, lights[l]);
      }
    },
    all(value) {
      for (let i = 0; i < 6; i++) {
        for (let j = 0; j < 8; j++) this.setValue(i, j, value);
      }
    },
    removePattern(id) {
      this.patterns = this.patterns.filter((x) => x.id != id);
    },
    getPattern(id) {
      for (let pattern of this.patterns) if (pattern.id == id) return pattern;
    },
    copyPattern(id) {
      this.patterns.push({ ...this.getPattern(id), id: this.patterns.length });
    },
    savePattern(name) {
      let toStore = [];
      let count = 0;
      for (let light of this.lights) {
        toStore[light.id[0] * 8 + light.id[1]] = light.on;
        if (light.on) count++;
      }
      if (count < 1) {
        alert("Pattern contains no lights!");
        return;
      }
      if (!name) name = prompt("Enter Pattern Name", this.patterns.length + 1);
      this.patterns.push({
        id: this.patterns.length,
        name,
        lights: toStore,
        count,
      });
    },
    getFrameColor(n) {
      return utils.getFrameColor(n);
    },
    toggle(light) {
      let id = light.id;
      if (this.liveUpdate) control.setPixel(id[0], id[1], !light.on);
      light.on = !light.on;
    },
    setValue(x, y, value) {
      for (let light of this.lights) {
        if (light.id[0] == x && light.id[1] == y) {
          if (value != light.on) {
            this.toggle(light);
          }
        }
      }
    },
    getColor(row) {
      switch (row) {
        case 0:
          return "white";
        case 1:
          return "green";
        case 2:
          return "blue";
        default:
          return "red";
      }
    },
    generateGrid() {
      let x_offset = 0.111,
        x_start = 0.1,
        y_offset = 0.143,
        y_start = 0.041;
      let lights = [];
      for (let i = 0; i < 6; i++) {
        if (i > 3) {
          y_start = 0.75;
          y_offset = 0.07;
          x_offset = 0.07;
          x_start = 0.45;
        }
        for (let j = 0; j < 8; j++) {
          lights.push({
            x: x_start + x_offset * j,
            y: y_start + y_offset * i,
            color: this.getColor(i),
            id: [i, j],
            on: false,
          });
        }
      }

      lights = [];
      lights = placements.map((x) => {
        return {
          x: x.x,
          y: x.y,
          color: x.color || x.Color,
          id: [x.grid - 1, x.segment - 1],
          on: false,
        };
      });

      console.log("lights", lights);
      return lights;
    },
  },
  data() {
    let lights = this.generateGrid();
    let patternsId = this.$route.params.patternsId || "";
    let patterns = Store.getPatterns(patternsId);
    return {
      liveUpdate: true,
      width: 700,
      height: 700,
      lights,
      digits: "00",
      patterns,
      timelineId: this.$route.params.timelineId || "",
      patternsId,
    };
  },
};
</script>

<style scoped>
.pattern {
  vertical-align: middle;
  border-top: solid black 1px;
}

.patterns{
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.pattern:nth-child(even) {
  background: rgb(228, 228, 228);
}

.keyBox {
  padding: 5px;
}
.board {
  background: url("./snowflake.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: contain;
  border: black 1px solid;
  margin: auto;
  margin-bottom: 10px;
}
.lit {
  opacity: 1 !important;
  border: 1px solid black !important;
}
.light {
  min-width: 30px;
  min-height: 30px;
  background: white;
  display: inline;
  position: absolute;
  border-radius: 10px;
  opacity: 0.9;
  border: 4px solid grey;
}
.digits {
  background: black;
  color: red;
  opacity: 0.8;
  border: white 1px solid;
  display: inline;
  position: absolute;
  font-size: 100px;
}

.keyframe {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 10px;
  float: left;
  border-radius: 15px;
  line-height: 30px;
  background-color: red;
  text-align: center;
}

.center {
  text-align: center;
}
</style>