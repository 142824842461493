import { connect }  from 'mqtt/dist/mqtt.js';
let client  = connect('wss://mqtt.johndews.com:443/mqtt');



let setPixel = function(x,y,value){
    if(value == true) value = 1;
    if(!value) value = 0;
    let payload = [x,y,value].join(',');
    //console.log(payload);
    client.publish('user/led', payload);
}


client.on('connect', function () {
    console.log("Mqtt connected.");
})






export default {
    client,
    setPixel
}

